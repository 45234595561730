import { createSlice } from '@reduxjs/toolkit';
import { defaultPagination } from '../search/pagination.D';
import asyncActions from './asyncActions';
import { ITransferSlice } from './index.D';

const initialState = {
  transfers: {
    items: [],
    info: defaultPagination,
  },
  transfer: null,
  isLoading: false,
  error: '',
  selected: [],
  currentAttachedFiles: [],
} as ITransferSlice;

const transfersSlice = createSlice({
  name: 'transfers',
  initialState,
  reducers: {
    clearTransfers(state: ITransferSlice) {
      state.transfers = {
        items: [],
        info: {
          current_page: 1,
          next_page: null,
          order: null,
          order_asc_desc: 'asc',
          per_page: 10,
          previous_page: null,
          total_entries: 0,
          total_pages: 1,
        },
      };

      return state;
    },
    clearTransfer(state: ITransferSlice) {
      state.transfer = null;

      return state;
    },
    clearCurrentAttachedFiles(state: ITransferSlice) {
      state.currentAttachedFiles = [];

      return state;
    },
  },
  extraReducers: {
    [asyncActions.getTransfer.fulfilled.type]: (state, action) => {
      state.transfer = action.payload;
    },
    [asyncActions.findTransfers.fulfilled.type]: (state, action) => {
      state.transfers = action.payload;
    },
    [asyncActions.findAllTransfers.fulfilled.type]: (state, action) => {
      state.transfers = action.payload;
    },
    [asyncActions.getTransfers.pending.type]: state => {
      state.isLoading = true;
    },
    [asyncActions.getTransfers.rejected.type]: state => {
      state.isLoading = false;
    },
    [asyncActions.getTransfers.fulfilled.type]: (state, action) => {
      state.transfers = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getTransfersByCustomer.fulfilled.type]: (state, action) => {
      state.transfers = action.payload;
    },
    [asyncActions.getTransferAttachedFiles.pending.type]: state => {
      state.isLoading = true;
    },
    [asyncActions.getTransferAttachedFiles.rejected.type]: state => {
      state.isLoading = false;
    },
    [asyncActions.getTransferAttachedFiles.fulfilled.type]: (state, action) => {
      state.currentAttachedFiles = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.addTransferAttachedFiles.pending.type]: state => {
      state.isLoading = true;
    },
    [asyncActions.addTransferAttachedFiles.rejected.type]: state => {
      state.isLoading = false;
    },
    [asyncActions.addTransferAttachedFiles.fulfilled.type]: (state, action) => {
      state.transfer = action.payload;
      state.isLoading = false;
      state.error = '';
    },
  },
});

export default {
  ...transfersSlice,
  asyncActions,
};
