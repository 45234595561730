export const convertFileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => resolve(typeof reader.result === 'string' ? reader.result : JSON.stringify(reader.result));
    reader.readAsDataURL(file);
    reader.onerror = e => reject(e || 'File error');
  });

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(typeof reader.result === 'string' ? reader.result : JSON.stringify(reader.result));
    reader.readAsDataURL(blob);
  });
};
