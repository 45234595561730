// AXIOS
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import { ICreateJurisdictionPayload } from '../../store/reducers/jurisdictions/types';

const { get, post, delete: del } = apiClient;

const URL = () => `organizations/${getBrand()}/jurisdictions`;

export default {
  getJurisdictions: () => get(URL()),
  createJurisdictions: (data: ICreateJurisdictionPayload) => post(URL(), data),
  bulkCreateJurisdictions: (codes: string[]) => post(`${URL()}/bulk`, codes),
  deleteJurisdictions: (id: string) => del(`${URL()}/${id}`),
  bulkDeleteJurisdictions: (ids: string[]) => del(`${URL()}/bulk?ids=${ids.join(',')}`),
};
