// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import objectToURL from '../../helpers/objectToURL';
import getBrand from '../../helpers/getBrand';
// TYPES
import { IDepositData, IRejectData, IWithdrawData } from 'app/store/reducers/riskAlert/index.D';

const { get, post, put } = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default {
  get: (params?: { [key: string]: string }) => get(`${URL()}/risk-alerts?${objectToURL(params)}`),
  findAlertByCustomer: (id: string) => get(`${URL()}/customers/${id}/risk-alerts/aml`),
  findAlertByBusiness: (id: string) => get(`${URL()}/business/${id}/risk-alerts/aml`),
  findAlertByTransfer: (id: string) => get(`${URL()}/transfers/${id}/risk-alert`),
  accept: (alertId: string, text: string, txId?: string) =>
    post(`${URL()}/risk-alerts/${alertId}/accept`, { text, txId }),
  findById: (alertId: string) => get(`${URL()}/risk-alerts/${alertId}`),
  reject: (alertId: string, text: string) => post(`${URL()}/risk-alerts/${alertId}/reject`, { text }),
  delete: (alertId: string, text: string) => post(`${URL()}/risk-alerts/${alertId}/delete`, { text }),
  update: (alertId: string, status: number) => put(`${URL()}/risk-alerts/${alertId}`, { status }),
  submitDeposit: (data: IDepositData) => post(`${URL()}/external-assets/submit-deposit`, data),
  submitWithdraw: (data: IWithdrawData) => post(`${URL()}/external-assets/submit-withdraw`, data),
  rejectTransaction: (data: IRejectData) => post(`${URL()}/external-assets/reject-transaction`, data),
  getRiskyTransactions: (params?: { [key: string]: string }) =>
    get(`${URL()}/risk-alerts/risk-transactions?${objectToURL(params)}`),
};
