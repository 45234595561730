import { createStyles, makeStyles } from '@mui/styles';
import toRem from 'app/helpers/toRem';

export default makeStyles(() =>
  createStyles({
    btnBlock: {
      flex: 1,
    },
    modal: {
      minWidth: toRem(350),
    },
  })
);
