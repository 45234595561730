// CORE
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// HOOKS
import useJigzoAdmin from './useJigzoAdmin';
// STORE
import organizationStore from '../store/reducers/organizations';
import { useAppSelector } from './redux.hook';

const useGetAllOrgs = (): null => {
  const dispatch = useDispatch();
  const admin = useAppSelector(state => state.admin.adminAuthed);
  const history = useHistory();
  const withoutAuthRoutes = ['/'];
  const isJigzoAdmin = useJigzoAdmin();

  useEffect(() => {
    if (!admin || withoutAuthRoutes.includes(history.location.pathname)) return;

    if (isJigzoAdmin) {
      dispatch(organizationStore.asyncActions.getAllOrganizations());
    }
  }, [admin]);

  return null;
};

export default useGetAllOrgs;
