import { apiClient } from 'app/clients/api.client';
import { getEnv } from 'app/configs';
import { blobToBase64 } from 'app/helpers/fileToBase64';
import { StaticFile } from './index.D';

const { get } = apiClient;
const BE_URL = getEnv('BE_URL');

export default {
  getPrivateStaticFiles: async (paths: string[]): Promise<StaticFile[]> => {
    const result = [];
    for await (const path of paths) {
      const file2 = (await get(`${BE_URL}/${path}`, { responseType: 'blob' })) as Blob;
      const base64 = await blobToBase64(file2);
      const filename = path.split('/').pop() ?? 'unknown';
      result.push({ filename, base64, path });
    }

    return result;
  },
};
