import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import fontsService from '../../../services/fonts';
// TYPES
import { IFont } from './index.D';

export default {
  findAllFonts: createAsyncThunk<IFont[]>(
    'fonts/find-all',
    async (_, thunkAPI) => await storeAddErrorNotification(() => fontsService.findAll(), thunkAPI, false)
  ),
};
