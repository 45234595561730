// HOOKS
import { useAppSelector } from './redux.hook';
// CONSTANTS
import routes from '../routes/routes';
// HOOKS
import useOperatedOrganization from '../hooks/useOperatedOrganization';
// TYPES
import { IRoutes } from '../routes/routes.D';
import useJigzoAdmin from './useJigzoAdmin';
import { adminRole } from '../store/reducers/roles/index.D';

const useMainRoutes = (): IRoutes[] => {
  const admin = useAppSelector(state => state.admin.adminAuthed);
  const organization = useAppSelector(state => state.organizations.organization);

  const currentOrganization = useOperatedOrganization();
  const isJigzoAdmin = useJigzoAdmin();

  const orgPermissions =
    admin?.roles?.find(role => role.organization === organization?._id)?.permissions ||
    admin?.roles?.find(role => role.role === adminRole.sysAdmin)?.permissions;

  const mainRoutes =
    !orgPermissions || isJigzoAdmin
      ? routes.mainRoutes.filter(
          route =>
            !route.orgTypes ||
            (currentOrganization && route.orgTypes.includes(currentOrganization.type)) ||
            (organization && route.orgTypes.includes(organization.type))
        )
      : routes.mainRoutes.filter(
          route => !route.permissions.length || route.permissions?.some((key: string) => orgPermissions.includes(key))
        );

  return mainRoutes;
};

export default useMainRoutes;
