// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import servicesBusiness from '../../../services/business';
// TYPES
import { IGetBusinessContacts } from './index.D';

export default {
  getBusinesses: createAsyncThunk(
    'business/getBusinesses',
    async (params: { [key: string]: any } | undefined, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBusiness.getBusinesses(params), thunkAPI, false)
  ),
  getBusiness: createAsyncThunk(
    'business/getBusiness',
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBusiness.getBusiness(params), thunkAPI, false)
  ),
  getBusinessContacts: createAsyncThunk(
    'business/getBusinessContacts',
    async (data: IGetBusinessContacts, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBusiness.getBusinessContacts(data), thunkAPI, false)
  ),
  getBusinessContact: createAsyncThunk(
    'business/getBusinessContact',
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBusiness.getBusinessContact(params), thunkAPI, false)
  ),
  updateBusiness: createAsyncThunk(
    'business/updateBusiness',
    async (params: { [key: string]: any }, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBusiness.updateBusiness(params), thunkAPI, false)
  ),
  updateRisk: createAsyncThunk(
    'business/updateRisk',
    async (params: { [key: string]: any }, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBusiness.updateRisk(params), thunkAPI, false)
  ),
  updateAddress: createAsyncThunk(
    'business/updateAddress',
    async (params: { [key: string]: any }, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBusiness.updateAddress(params), thunkAPI, false)
  ),
  updateStatus: createAsyncThunk(
    'business/updateStatus',
    async (params: { [key: string]: any }, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBusiness.updateStatus(params), thunkAPI, false)
  ),
  deleteBusinessContact: createAsyncThunk(
    'business/deleteBusiness',
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBusiness.deleteBusinessContact(params), thunkAPI, false)
  ),
  changeBusinessStatus: createAsyncThunk(
    'business/status/change',
    async (params: { businessId: string; status: number }, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBusiness.changeBusinessStatus(params), thunkAPI, false)
  ),
  getBusinessQuestionnaire: createAsyncThunk(
    'business/getBusinessQuestionnaire',
    async ({ businessId }: { businessId: string }, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBusiness.getBusinessQuestionnaire(businessId), thunkAPI, false)
  ),
  getUboVerifications: createAsyncThunk(
    'business/getUboVerifications',
    async ({ businessId }: { businessId: string }, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBusiness.getUboVerifications(businessId), thunkAPI, false)
  ),
  getPotentialOwners: createAsyncThunk(
    'business/getPotentialOwners',
    async ({ businessId }: { businessId: string }, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBusiness.getPotentialOwners(businessId), thunkAPI, false)
  ),
  transferOwnership: createAsyncThunk(
    'business/transferOwnership',
    async (data: { id: string; contactId: string }, thunkAPI) =>
      await storeAddErrorNotification(() => servicesBusiness.transferOwnership(data), thunkAPI, false)
  ),
};
