// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from 'app/helpers/errors';
// SERVICE
import organizationSettingsService from 'app/services/organizationSettings';
// TYPES
import { IUpdatePrimaryCurrency, OrganizationSettings, PartialUpdateOrganizationSettings } from './index.D';

export default {
  getPrimaryCurrency: createAsyncThunk(
    'organizationSettings/getPrimaryCurrency',
    async (organizationId: string, thunkAPI) =>
      await storeAddErrorNotification(() => organizationSettingsService.getPrimaryCurrency(organizationId), thunkAPI)
  ),
  updatePrimaryCurrency: createAsyncThunk(
    'organizationSettings/updatePrimaryCurrency',
    async (data: IUpdatePrimaryCurrency, thunkAPI) =>
      await storeAddErrorNotification(() => organizationSettingsService.updatePrimaryCurrency(data), thunkAPI)
  ),
  read: createAsyncThunk<OrganizationSettings, string>(
    'organizationSettings/read',
    async (organizationId, thunkAPI) =>
      await storeAddErrorNotification(() => organizationSettingsService.read(organizationId), thunkAPI)
  ),
  updatePartial: createAsyncThunk<OrganizationSettings, PartialUpdateOrganizationSettings>(
    'organizationSettings/updatePartial',
    async (data, thunkAPI) =>
      await storeAddErrorNotification(() => organizationSettingsService.updatePartial(data), thunkAPI)
  ),
};
