// CORE
import { createSlice } from '@reduxjs/toolkit';
// SYNC ACTIONS
import asyncActions from './asyncActions';
// TYPES
import { IOrganizationSettingsState } from './index.D';
import { defaultColorSchema } from 'app/helpers/organizationSettings';

const defaultFont = { name: '', importUrl: '' };

const initialState: IOrganizationSettingsState = {
  loading: false,
  primaryCurrency: null,
  icons: {},
  colorSchema: {},
  font: {
    main: defaultFont,
    fallback: defaultFont,
  },
};

const organizationSettingsSlice = createSlice({
  name: 'organizationSettings',
  initialState,
  reducers: {
    clear: state => {
      state.loading = false;
      state.primaryCurrency = null;
    },
  },
  extraReducers: {
    /*** PRIMARY CURRENCY ***/
    // READ
    [asyncActions.getPrimaryCurrency.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.getPrimaryCurrency.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.getPrimaryCurrency.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.primaryCurrency = payload;
    },
    // UPDATE
    [asyncActions.updatePrimaryCurrency.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.updatePrimaryCurrency.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.updatePrimaryCurrency.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.primaryCurrency = payload;
    },
    [asyncActions.read.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.read.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.read.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.primaryCurrency = payload?.primaryCurrency || null;
      state.icons = payload?.icons || {};
      state.colorSchema = payload?.colorSchema || defaultColorSchema;
      state.font = {
        ...state.font,
        ...payload.font,
      };
    },
    [asyncActions.updatePartial.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.updatePartial.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.updatePartial.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.primaryCurrency = payload?.primaryCurrency || null;
      state.icons = payload?.icons || {};
      state.colorSchema = payload?.colorSchema || defaultColorSchema;
      state.font = payload.font ?? { main: defaultFont, fallback: defaultFont };
    },
  },
});

export default {
  ...organizationSettingsSlice,
  asyncActions,
};
