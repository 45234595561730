import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import asyncActions from './asyncActions';
import { ICustomer, ICustomersSlice } from './index.D';
import { defaultPagination } from '../search/pagination.D';

const initialState = {
  customers: {
    items: [],
    info: defaultPagination,
  },
  isLoading: false,
  error: '',
  selected: [],
} as ICustomersSlice;

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setSelected: (state: ICustomersSlice, payload: PayloadAction<Array<ICustomer['_id']> | []>) => {
      state.selected = payload.payload;
    },
  },
  extraReducers: {
    [asyncActions.getCustomers.fulfilled.type]: (state: ICustomersSlice, action) => {
      state.customers = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getCustomers.pending.type]: (state: ICustomersSlice) => {
      state.isLoading = true;
      state.customers = { items: [], info: defaultPagination };
    },
    [asyncActions.getCustomers.rejected.type]: (state: ICustomersSlice, action: PayloadAction<ICustomersSlice>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [asyncActions.updateCustomer.fulfilled.type]: (state: ICustomersSlice, { payload }: PayloadAction<ICustomer>) => {
      const index = state.customers.items.findIndex(({ _id }) => _id === payload._id);
      const customer = state.customers.items[index];
      const newCustomers = [...state.customers.items];
      newCustomers[index] = { ...customer, ...payload };
      state.customers.items = newCustomers;
    },
  },
});

export default {
  ...customersSlice,
  asyncActions,
};
