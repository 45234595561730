import { ICountry } from '../countries/index.D';
import { DataPooledAccounts } from 'components/modules/Customer/CustomerTable/index.D';

export interface IHolder {
  _id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  position?: string;
  createdAt: Date;
  updatedAt: Date;
  dateOfBirth?: Date;
}

interface IContact {
  _id: string;
  verifiedEmail: boolean;
  verifiedAt?: string;
  email: string;
  phone: string;
  createdAt: Date;
  updatedAt: Date;
}
export enum RiskLevels {
  NA,
  high,
  medium,
  low,
}

export enum customerStatus {
  new,
  delete,
  reject,
  review,
  suspend,
  approve,
  dormant,
  blocked,
}

export interface IVerification {
  amlPassed: boolean;
  amlUpdateAt: string;
  kycPassed: boolean;
  kycUpdateAt: string;
  amlDeclinedReason?: string;
  kycDeclinedReason?: string;
  _id: string;
  status: verificationStatuses;
}

export enum verificationStatuses {
  start,
  pending,
  reject,
  successful,
}

export interface ICustomer {
  address: IAddress;
  filter(arg0: ({ id }: { id: string }) => boolean): ICustomer;
  _id: string;
  jigzoId?: string;
  type: number;
  risk: RiskLevels;
  status: number;
  fhStatus?: 'APPROVED' | 'REJECTED' | 'IN_REVIEW' | 'UNEVALUATED';
  holder: IHolder;
  contact: IContact;
  purposeOfAccount: string;
  verification: IVerification;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  isUBO: boolean;
  amountOfBlock?: number;
  spVerification?: {
    reference: string;
    id: string;
  };
  pooledAccounts?: DataPooledAccounts[];
}

export interface IAddress {
  fullAddress: string;
  city: string;
  country: ICountry;
  createdAt: Date;
  postcode: string;
  street: string;
  updatedAt: Date;
}

export interface ICustomerSlice {
  customer: ICustomer;
  avatar?: string | null;
  isLoading: boolean;
  error?: any;
}
