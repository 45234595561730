import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/approved-partner-performance',
    title: 'Approved Partner Performance',
    component: lazy(() => import('components/views/NotFound')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: [],
    routes: [],
  },
] as IRoutes[];
