// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import objectToURL from '../../helpers/objectToURL';
import getBrand from '../../helpers/getBrand';
// TYPES
import { ICreateMonthlyFee, IReportQuerys } from '../../store/reducers/reports/index.D';

const { get, post, put } = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default {
  findLatestMonthlyFees: () => get(`${URL()}/monthly-fees/latest`),
  createMonthlyFees: (fee: ICreateMonthlyFee) => post(`${URL()}monthly-fees`, fee),
  updateMonthlyFees: (id: string, fee: ICreateMonthlyFee) => put(`${URL()}/monthly-fees/${id}`, fee),
  findMonthlyFees: (params?: IReportQuerys) => get(`${URL()}/monthly-fees?${objectToURL(params)}`),
  getMonthlyFeesReport: (params?: IReportQuerys) => get(`${URL()}/reports/monthly-fees?${objectToURL(params)}`),
  findMargins: (params?: IReportQuerys) => get(`${URL()}/reports/margins?${objectToURL(params)}`),
  findFees: (params?: IReportQuerys) => get(`${URL()}/reports/fees?${objectToURL(params)}`),
  findRevenueShare: (params?: IReportQuerys) => get(`${URL()}/reports/revenue-share?${objectToURL(params)}`),
  findPayments: (params?: IReportQuerys) => get(`${URL()}/reports/payments?${objectToURL(params)}`),
  findConversions: (params?: IReportQuerys) => get(`${URL()}/reports/conversions?${objectToURL(params)}`),
  findFunds: (params?: IReportQuerys) => get(`${URL()}/reports/funding?${objectToURL(params)}`),
  findCardLoads: (params?: IReportQuerys) => get(`${URL()}/reports/card-loads?${objectToURL(params)}`),
  getForecastDefaults: () => get(`${URL()}/reports/forecast-defaults`),

  getDashboardCustomers: (params: Record<string, unknown>) => get(`${URL()}/reports/customers?${objectToURL(params)}`),
  getDashboardVolumes: (params: Record<string, unknown>) => get(`${URL()}/reports/volumes?${objectToURL(params)}`),
  getDashboardEarnings: (params: Record<string, unknown>) => get(`${URL()}/reports/earnings?${objectToURL(params)}`),
  getDashboardTransactions: (params: Record<string, unknown>) =>
    get(`${URL()}/reports/transactions?${objectToURL(params)}`),
  getForecastData: () => get(`${URL()}/reports/forecast`),
  saveForecastConfig: (data: Record<string, unknown>) => post(`${URL()}/reports/forecast`, data),
};
