import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import jurisdictionsService from '../../../services/jurisdictions';
// TYPES
import { ICreateJurisdictionPayload } from './types';

export default {
  get: createAsyncThunk(
    'jurisdictions/get',
    async (_, thunkAPI) => await storeAddErrorNotification(() => jurisdictionsService.getJurisdictions(), thunkAPI)
  ),
  create: createAsyncThunk(
    'jurisdictions/create',
    async (data: ICreateJurisdictionPayload, thunkAPI) =>
      await storeAddErrorNotification(() => jurisdictionsService.createJurisdictions(data), thunkAPI)
  ),
  bulkCreate: createAsyncThunk(
    'jurisdictions/createBulk',
    async ({ codes }: { codes: string[] }, thunkAPI) =>
      await storeAddErrorNotification(() => jurisdictionsService.bulkCreateJurisdictions(codes), thunkAPI)
  ),
  delete: createAsyncThunk(
    'jurisdictions/delete',
    async ({ id }: { id: string }, thunkAPI) =>
      await storeAddErrorNotification(() => jurisdictionsService.deleteJurisdictions(id), thunkAPI)
  ),
  bulkDelete: createAsyncThunk(
    'jurisdictions/deleteBulk',
    async ({ ids }: { ids: string[] }, thunkAPI) =>
      await storeAddErrorNotification(() => jurisdictionsService.bulkDeleteJurisdictions(ids), thunkAPI)
  ),
};
