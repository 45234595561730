// API
import { apiClient } from 'app/clients/api.client';
// HELPERS
import getBrand from 'app/helpers/getBrand';
// TYPES
import {
  IUpdatePrimaryCurrency,
  OrganizationSettings,
  PartialUpdateOrganizationSettings,
} from 'app/store/reducers/organizationSettings/index.D';

type PrimaryCurrencyResult = Promise<OrganizationSettings['primaryCurrency']>;

const { get, put, patch } = apiClient;

const URL = (organizationId?: string) => `organizations/${organizationId || getBrand()}/settings`;

export default {
  getPrimaryCurrency: (organizationId: string): PrimaryCurrencyResult => get(`${URL(organizationId)}/primary-currency`),
  updatePrimaryCurrency: (data: IUpdatePrimaryCurrency): PrimaryCurrencyResult => put(URL(data.organizationId), data),
  read: (organizationId: string): Promise<OrganizationSettings> => get(URL(organizationId)),
  updatePartial: (data: PartialUpdateOrganizationSettings): Promise<OrganizationSettings> =>
    patch(URL(data.organizationId), data),
};
