// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICE
import digitalAssetNetworksService from '../../../services/digitalAssetNetworks';
// TYPES
import { IDigitalAssetNetwork } from './index.D';

export default {
  getDigitalAssetNetworks: createAsyncThunk(
    'digitalAssetNetworks/get',
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(() => digitalAssetNetworksService.getAllAssetNetworks(id), thunkAPI, false)
  ),
  editDigitalAssetNetwork: createAsyncThunk(
    'digitalAssetNetworks/edit',
    async (data: Partial<IDigitalAssetNetwork>, thunkAPI) =>
      await storeAddErrorNotification(() => digitalAssetNetworksService.editDigitalAssets(data), thunkAPI, false)
  ),
};
