// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import objectToURL from '../../helpers/objectToURL';
import getBrand from '../../helpers/getBrand';
// TYPES
import { IQueriesCurrency } from '../../store/reducers/currencies/index.D';

const { get } = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default {
  get: (params: IQueriesCurrency) => get(`${URL()}/currencies?${objectToURL(params)}`),
  getCryptoConvertable: () => get(`${URL()}/currencies/crypto-convertable`),
  getAll: () => get(`${URL()}/currencies/list`),
};
