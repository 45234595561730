// CORE
// eslint-disable-next-line import/namespace
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import marginFeeService from 'app/services/marginsFees';
// TYPES
import { ICreateMarginFeePayload, ReadFee } from './index.D';
import { WithOrganizationId } from 'app/store/types';

export default {
  create: createAsyncThunk(
    'marginFee/create',
    async (data: ICreateMarginFeePayload & WithOrganizationId, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.create(data), thunkAPI)
  ),
  update: createAsyncThunk(
    'marginFee/update',
    async ({ id, data }: { id: string; data: Partial<ICreateMarginFeePayload> }, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.update(id, data), thunkAPI)
  ),

  delete: createAsyncThunk(
    'marginFee/delete',
    async (id: string, thunkAPI) => await storeAddErrorNotification(() => marginFeeService.delete(id), thunkAPI)
  ),

  readAccountPlatformFees: createAsyncThunk(
    'accountPlatformFees/read',
    async (data: ReadFee, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.readAccountPlatformFees(data), thunkAPI)
  ),

  readAccountConversionPair: createAsyncThunk(
    'accountConversionPair/read',
    async (data: ReadFee, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.readAccountConversionPair(data), thunkAPI)
  ),

  readFiatConversionGlobal: createAsyncThunk(
    'fiatConversionGlobal/read',
    async (data: ReadFee, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.readFiatConversionGlobal(data), thunkAPI)
  ),

  readAssetFees: createAsyncThunk(
    'assetFees/read',
    async (data: ReadFee, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.readAssetFees(data), thunkAPI)
  ),

  readAssetTradingPairFees: createAsyncThunk(
    'assetTradingPairFees/read',
    async (data: ReadFee, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.readAssetTradingPairFees(data), thunkAPI)
  ),

  readAssetTradingGlobalFees: createAsyncThunk(
    'assetTradingGlobalFees/read',
    async (data: ReadFee, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.readAssetTradingGlobalFees(data), thunkAPI)
  ),

  readConversionCryptoPair: createAsyncThunk(
    'conversionCryptoPair/read',
    async (data: ReadFee, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.readConversionCryptoPair(data), thunkAPI)
  ),

  readConversionCryptoGlobal: createAsyncThunk(
    'conversionCryptoGlobal/read',
    async (data: ReadFee, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.readConversionCryptoGlobal(data), thunkAPI)
  ),

  readTransferFees: createAsyncThunk(
    'transferFees/read',
    async (data: ReadFee, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.readTransferFees(data), thunkAPI)
  ),
};
