// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import marginFeeService from 'app/services/marginsFees';
// TYPES
import { ICreateMiscellaneousFeePayload } from 'app/store/reducers/miscellaneousFees/index.D';

export default {
  get: createAsyncThunk(
    'miscellaneousFees/get',
    async (_, thunkAPI) => await storeAddErrorNotification(() => marginFeeService.getMiscellaneousFees(), thunkAPI)
  ),
  create: createAsyncThunk(
    'miscellaneousFees/create',
    async (data: ICreateMiscellaneousFeePayload, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.createMiscellaneousFee(data), thunkAPI)
  ),
  update: createAsyncThunk(
    'miscellaneousFees/update',
    async (data: { id: string; data: Partial<ICreateMiscellaneousFeePayload> }, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.updateMiscellaneousFee(data), thunkAPI)
  ),
  delete: createAsyncThunk(
    'miscellaneousFees/delete',
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(() => marginFeeService.deleteMiscellaneousFee(id), thunkAPI)
  ),
};
