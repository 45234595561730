import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/activity-revenue-and-cost-summary',
    title: 'Activity Revenue And Cost Summary',
    component: lazy(() => import('components/views/NotFound')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: [],
    routes: [],
  },
] as IRoutes[];
