import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarKey, useSnackbar } from 'notistack';
import notifierModule from 'app/store/reducers/notifier';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const { removeNotification } = notifierModule.actions;
let displayed: string[] = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector<any, any[]>(({ notifier }) => notifier);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const storeDisplayed = (id: string) => {
    displayed = [...displayed, id];
  };

  const removeNotify = (id: SnackbarKey) => {
    displayed = [...displayed.filter(key => id !== key)];
    dispatch(removeNotification(id));
  };

  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);

        return;
      }

      if (displayed.includes(key)) return;

      enqueueSnackbar(message, {
        key,
        persist: false,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => removeNotify(myKey),
        action: myKey => (
          <IconButton onClick={() => closeSnackbar(myKey)}>
            <Close />
          </IconButton>
        ),
      });

      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar]);

  return null;
};

export default Notifier;
