import { getEnv } from 'app/configs';
import axios, { AxiosHeaders, AxiosResponse } from 'axios';
import {
  getRefreshToken,
  removeRefreshToken,
  removeActivityData,
  setAccessToken,
  setIdleTimeout,
  setRefreshToken,
  setSessionTime,
} from '../helpers/localStorage';

const tempTokenRoutes = ['auth/login', 'auth/phone', '2fa/verify'];

const apiClient = axios.create({
  baseURL: getEnv('API_URL'),
});

apiClient.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

apiClient.defaults.withCredentials = true;
apiClient.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const unAuthEvent = new Event('unAuth');
const clearSession = () => {
  document.dispatchEvent(unAuthEvent);
  removeRefreshToken();
  removeActivityData();
};

let fetchRefreshTokenRequest: Promise<AxiosResponse | void> | null = null;

const fetchTokens = async (refreshToken: string) => {
  try {
    return await axios.put(
      `${getEnv('API_URL')}auth`,
      {},
      {
        withCredentials: true,
        headers: { Authorization: `Bearer ${refreshToken}` },
      }
    );
  } catch (e) {
    console.error(e);
  }
};

apiClient.interceptors.request.use(
  async config => {
    try {
      if (config.url === 'auth') return config;

      if (tempTokenRoutes.some(route => config.url?.includes(route)) && !!config.headers) {
        if (config.url === 'auth/login') return config;

        const tempToken = localStorage.getItem('tempToken');
        if (tempToken) {
          config.headers.Authorization = `Bearer ${tempToken}`;
        }

        return config;
      }

      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');
      const sessionTime = +(localStorage.getItem('session_time') ?? 0);

      if (config.headers) {
        if (config.url?.toLowerCase() === 'auth/' && config.method && ['put'].includes(config.method.toLowerCase())) {
          config.headers.Authorization = `Bearer ${refreshToken}`;
        } else {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      } else {
        config.headers = new AxiosHeaders();
        if (config.url?.toLowerCase() === 'auth/' && config.method && ['put'].includes(config.method.toLowerCase())) {
          config.headers.Authorization = `Bearer ${refreshToken}`;
        } else {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }

      const dateNow = Date.now();

      if (refreshToken && sessionTime != null && dateNow > sessionTime && config.url?.toLowerCase() !== 'auth/') {
        if (!fetchRefreshTokenRequest) {
          fetchRefreshTokenRequest = fetchTokens(refreshToken);
        }

        const response = await fetchRefreshTokenRequest;
        setSessionTime(response?.data?.sessionTime);
        setRefreshToken(response?.data?.refreshToken);
        setAccessToken(response?.data?.accessToken);
        setIdleTimeout(response?.data?.idleTimeout);

        if (config.headers) {
          config.headers.Authorization = `Bearer ${response?.data?.accessToken}`;
        } else {
          config.headers = new AxiosHeaders();
          config.headers.Authorization = `Bearer ${response?.data?.accessToken}`;
        }
      }

      return config;
    } catch (e) {
      console.error(e);
    } finally {
      fetchRefreshTokenRequest = null;
    }

    return config;
  },
  error => Promise.reject(error)
);

apiClient.interceptors.response.use(
  response => {
    const { data = {} } = response;
    if (!response.data) return response.data;

    const { sessionTime = 0, refreshToken = '', accessToken, idleTimeout } = data;
    if (sessionTime) setSessionTime(sessionTime);
    if (refreshToken) setRefreshToken(refreshToken);
    if (accessToken) setAccessToken(accessToken);
    if (idleTimeout) setIdleTimeout(idleTimeout);

    return response.data || response;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response?.status === 401) {
      if (originalRequest.url !== 'auth' && !originalRequest.url.includes('profile')) {
        const refreshToken = getRefreshToken();
        if (refreshToken) {
          try {
            const res = await apiClient.put(
              'auth',
              {},
              {
                headers: {
                  Authorization: 'Bearer ' + refreshToken,
                },
              }
            );

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const accessToken = res.accessToken;
            setAccessToken(accessToken);

            originalRequest.headers.Authorization = 'Bearer ' + accessToken;

            return apiClient(originalRequest);
          } catch (error) {
            clearSession();
            localStorage.setItem('logoutReason', 'sessionTimeout');
            window.location.reload();
          }
        }
      } else {
        clearSession();
        if (!originalRequest.url.includes('profile')) window.location.reload();
      }
    }

    return Promise.reject(error);
  }
);

export { apiClient };
