export const defaultColorSchema = {
  primaryColor: '#000000',
  secondaryColor: '#E6E6E6',
  secondaryTextColor: '#000000',
  backgroundColor: '#FFFFFF',
  fontColor: '#000000',
  mainMenuLinkColor: '#FFFFFF',
  menuLinkAndIconsHoverColor: '#717171',
} as const;

export const iconsKeys = [
  'primaryLogoBase64',
  'secondaryLogoBase64',
  'introGraphicBase64',
  'signInGraphicBase64',
  'faviconBase64',
] as const;
