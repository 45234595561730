// TYPES
import { IRoutes } from './routes.D';
// ROUTES
import adminRoutes from './subRoutes/admin.routes';
import beneficiaryRoutes from './subRoutes/beneficiary.routes';
import businessRoutes from './subRoutes/business.routes';
import businessContactRoutes from './subRoutes/businessContact.routes';
import customerRoutes from './subRoutes/customer.routes';
import dashboardRoutes from './subRoutes/dashboard.routes';
import marginFeeRoutes from './subRoutes/marginFees.routes';
import reportRoutes from './subRoutes/report.routes';
import riskAlertRoutes from './subRoutes/riskAlert.routes';
import transferRoutes from './subRoutes/transfer.routes';
import forecastRoutes from './subRoutes/forecast.route';
import jurisdictionsRoutes from './subRoutes/jurisdictions.routes';
import digitalAssetsRoutes from './subRoutes/digitalAssets.routes';
import businessActivityRoutes from './subRoutes/businessActivity.routes';
import pricePlansRoutes from './subRoutes/pricePlan.routes';
import registrationRequestRoutes from './subRoutes/registrationRequest.routes';
import transactionsCosts from './subRoutes/transactionsCosts.routes';
import questionnaireRoutes from './subRoutes/questionnaire.route';
import organizationsRoutes from './subRoutes/organizations.routes';
import withdrawalRules from './subRoutes/withdrawalRules';
import jigzoBusinessOverviewRoutes from './subRoutes/jigzoBusinessOverview.routes';
import activityRevenueAndCostSummaryRoutes from './subRoutes/activityRevenueAndCostSummary.routes';
import customerProfilingRoutes from './subRoutes/customerProfiling.routes';
import approvedPartnerPerformanceRoutes from './subRoutes/approvedPartnerPerformance.routes';

export default [
  ...adminRoutes,
  ...beneficiaryRoutes,
  ...businessRoutes,
  ...businessContactRoutes,
  ...customerRoutes,
  ...dashboardRoutes,
  ...marginFeeRoutes,
  ...reportRoutes,
  ...riskAlertRoutes,
  ...transferRoutes,
  ...forecastRoutes,
  ...jurisdictionsRoutes,
  ...digitalAssetsRoutes,
  ...businessActivityRoutes,
  ...pricePlansRoutes,
  ...registrationRequestRoutes,
  ...transactionsCosts,
  ...questionnaireRoutes,
  ...organizationsRoutes,
  ...withdrawalRules,
  ...jigzoBusinessOverviewRoutes,
  ...activityRevenueAndCostSummaryRoutes,
  ...customerProfilingRoutes,
  ...approvedPartnerPerformanceRoutes,
] as IRoutes[];
