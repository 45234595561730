import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// TYPES
import { IFont, IFontsStore } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';

const initialState: IFontsStore = {
  fonts: [],
  isLoading: false,
};

const fontSlice = createSlice({
  name: 'fonts',
  initialState,
  reducers: {},
  extraReducers: {
    [asyncActions.findAllFonts.fulfilled.type]: (state, action: PayloadAction<IFont[]>) => {
      state.fonts = action.payload.sort((a, b) => a.name.localeCompare(b.name));
      state.isLoading = false;
    },
    [asyncActions.findAllFonts.pending.type]: state => {
      state.isLoading = true;
    },
    [asyncActions.findAllFonts.rejected.type]: state => {
      state.isLoading = false;
    },
  },
});

export default {
  ...fontSlice,
  asyncActions,
};
