import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/approved-partners',
    title: 'Approved Partners',
    component: lazy(() => import('components/views/Organizations')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['admin_organizations_read'],
    routes: [],
  },
  {
    path: '/approved-partners/create',
    title: 'Create Approved Partner',
    component: lazy(() => import('components/modules/form/CreateOrganizationForm')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['admin_organizations_create'],
    routes: [],
  },
  {
    path: '/approved-partners/:id',
    title: 'Approved Partner details',
    component: lazy(() => import('components/views/OrganizationDetail')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['admin_organizations_read'],
    routes: [
      {
        title: 'Approved Partners',
        path: '/approved-partners',
        params: [],
      },
    ],
  },
  {
    path: '/approved-partners/:id/edit',
    title: 'Edit Approved Partner',
    component: lazy(() => import('components/modules/form/OrganizationEditForm')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['admin_organizations_update', 'admin_organizations_delete'],
    routes: [
      {
        title: 'Approved Partners',
        path: '/approved-partners',
        params: [],
      },
      {
        title: 'Approved Partner details',
        path: '/approved-partners/:id',
        params: ['id'],
      },
    ],
  },
] as IRoutes[];
