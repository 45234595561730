// CORE
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// TYPES
import { IMiscellaneousFee, IMiscellaneousFeesSlice } from 'app/store/reducers/miscellaneousFees/index.D';
// ACTIONS
import asyncActions from './asyncActions';

const initialState: IMiscellaneousFeesSlice = {
  items: [],
  loading: false,
  actionLoading: false,
};

const miscellaneousFeesSlice = createSlice({
  name: 'miscellaneousFees',
  initialState,
  reducers: {},
  extraReducers: {
    // GET
    [asyncActions.get.pending.type]: state => {
      state.loading = true;
    },
    [asyncActions.get.rejected.type]: state => {
      state.loading = false;
    },
    [asyncActions.get.fulfilled.type]: (state, action) => {
      state.items = action.payload;
      state.loading = false;
    },
    // CREATE
    [asyncActions.create.pending.type]: state => {
      state.actionLoading = true;
    },
    [asyncActions.create.rejected.type]: state => {
      state.actionLoading = false;
    },
    [asyncActions.create.fulfilled.type]: (state, action: PayloadAction<IMiscellaneousFee>) => {
      state.items = [...state.items, action.payload];
      state.actionLoading = false;
    },
    // UPDATE
    [asyncActions.update.pending.type]: state => {
      state.actionLoading = true;
    },
    [asyncActions.update.rejected.type]: state => {
      state.actionLoading = false;
    },
    [asyncActions.update.fulfilled.type]: (state, action: PayloadAction<IMiscellaneousFee>) => {
      state.items = state.items.map(fee => {
        if (fee._id === action.payload._id) {
          return action.payload;
        } else {
          return fee;
        }
      });
      state.actionLoading = false;
    },
    // DELETE
    [asyncActions.delete.pending.type]: state => {
      state.actionLoading = true;
    },
    [asyncActions.delete.rejected.type]: state => {
      state.actionLoading = false;
    },
    [asyncActions.delete.fulfilled.type]: (state, action: PayloadAction<IMiscellaneousFee>) => {
      state.items = state.items.filter(fee => fee._id !== action.payload._id);
      state.actionLoading = false;
    },
  },
});

export default {
  ...miscellaneousFeesSlice,
  asyncActions,
};
