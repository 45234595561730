// API
import { apiClient } from 'app/clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import {
  IWithdrawalRule,
  CreateWithdrawalRulePayload,
  UpdateWithdrawalRulePayload,
} from 'app/store/reducers/withdrawalRules/index.D';

const { get, post, put, delete: del } = apiClient;

const URL = () => `organizations/${getBrand()}/withdrawal-rules`;

export default {
  getRules: (): Promise<IWithdrawalRule[]> => get(URL()),
  createRule: (data: CreateWithdrawalRulePayload): Promise<IWithdrawalRule> => post(URL(), data),
  updateRule: (id: string, data: UpdateWithdrawalRulePayload): Promise<IWithdrawalRule> => put(`${URL()}/${id}`, data),
  deleteRule: (id: string): Promise<IWithdrawalRule['_id']> => del(`${URL()}/${id}`),
};
