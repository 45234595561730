// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from 'app/helpers/errors';
// SERVICE
import withdrawalRulesService from 'app/services/withdrawalRules';
// TYPES
import { CreateWithdrawalRulePayload, UpdateWithdrawalRulePayload } from './index.D';

export default {
  getWithdrawalRules: createAsyncThunk(
    'withdrawalRules/get',
    async (_, thunkAPI) => await storeAddErrorNotification(() => withdrawalRulesService.getRules(), thunkAPI)
  ),
  createWithdrawalRule: createAsyncThunk(
    'withdrawalRules/create',
    async (data: CreateWithdrawalRulePayload, thunkAPI) =>
      await storeAddErrorNotification(() => withdrawalRulesService.createRule(data), thunkAPI)
  ),
  updateWithdrawalRule: createAsyncThunk(
    'withdrawalRules/update',
    async ({ id, data }: { id: string; data: UpdateWithdrawalRulePayload }, thunkAPI) =>
      await storeAddErrorNotification(() => withdrawalRulesService.updateRule(id, data), thunkAPI)
  ),
  deleteWithdrawalRule: createAsyncThunk(
    'withdrawalRules/delete',
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(() => withdrawalRulesService.deleteRule(id), thunkAPI)
  ),
};
