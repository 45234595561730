interface IConfig {
  NODE_ENV: string;
  API_URL: string;
  BE_URL: string;
  ENVIRONMENT: string;
}

const config = {
  NODE_ENV: process.env.NODE_ENV || 'development',
  API_URL: process.env.REACT_APP_API_URL || '/',
  BE_URL: process.env.REACT_APP_BE_URL || '/',
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || '',
} as IConfig;

export const getEnv = (key: keyof IConfig): string => {
  if (!config[key]) {
    throw new Error(`Environment variable ${key} should be specified`);
  }

  return config[key];
};

export const isDevelopmentMode = getEnv('NODE_ENV') === 'development';
