import { createSlice } from '@reduxjs/toolkit';
// TYPES
import { IDigitalAssetNetworksSlice } from './index.D';
// ACTIONS
import asyncActions from './asyncActions';

const initialState = {
  items: [],
  isLoading: false,
} as IDigitalAssetNetworksSlice;

const digitalAssetNetworksSlice = createSlice({
  name: 'digitalAssetNetworks',
  initialState,
  reducers: {},
  extraReducers: {
    [asyncActions.getDigitalAssetNetworks.pending.type]: (state: IDigitalAssetNetworksSlice) => {
      state.isLoading = true;
    },
    [asyncActions.getDigitalAssetNetworks.rejected.type]: (state: IDigitalAssetNetworksSlice) => {
      state.isLoading = false;
    },
    [asyncActions.getDigitalAssetNetworks.fulfilled.type]: (state: IDigitalAssetNetworksSlice, { payload }) => {
      state.isLoading = false;
      state.items = payload;
    },

    [asyncActions.editDigitalAssetNetwork.pending.type]: (state: IDigitalAssetNetworksSlice) => {
      state.isLoading = true;
    },
    [asyncActions.editDigitalAssetNetwork.rejected.type]: (state: IDigitalAssetNetworksSlice) => {
      state.isLoading = false;
    },
    [asyncActions.editDigitalAssetNetwork.fulfilled.type]: (state: IDigitalAssetNetworksSlice, { payload }) => {
      state.items = state.items.map(item => {
        if (item.name === payload.name) return payload;

        return item;
      });
      state.isLoading = false;
    },
  },
});

export default {
  ...digitalAssetNetworksSlice,
  asyncActions,
};
