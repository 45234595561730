// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import { AnnouncementData } from 'app/store/reducers/announcements/index.D';

const { post } = apiClient;
const URL = () => `organizations/${getBrand()}/announcements`;

export default {
  sendGlobalAnnouncement: (data: AnnouncementData) => post<AnnouncementData>(`${URL()}/send-global`, data),
};
