import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import announcementService from '../../../services/announcement';
// TYPES
import { AnnouncementData } from './index.D';

export default {
  sendGlobalAnnouncement: createAsyncThunk(
    'announcements/send-global',
    async (data: AnnouncementData, thunkAPI) =>
      await storeAddErrorNotification(() => announcementService.sendGlobalAnnouncement(data), thunkAPI, false)
  ),
};
