import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/:urlOrgId/businesses',
    title: 'Businesses',
    component: lazy(() => import('components/views/Businesses')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['businesses_read', 'businesses_report'],
    routes: [],
  },
  {
    path: '/:urlOrgId/businesses/:businessId',
    title: 'Business Details',
    component: lazy(() => import('components/views/BusinessDetail')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['businesses_read', 'businesses_report'],
    routes: [
      {
        title: 'Businesses',
        path: '/:urlOrgId/businesses',
        params: [],
      },
    ],
  },
  {
    path: '/:urlOrgId/businesses/:businessId/edit',
    title: 'Edit Business',
    component: lazy(() => import('components/modules/form/BusinessEditForm')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['businesses_update'],
    routes: [
      {
        title: 'Businesses',
        path: '/:urlOrgId/businesses',
        params: [],
      },
      {
        title: 'Business Details',
        path: '/:urlOrgId/businesses/:businessId',
        params: ['businessId'],
      },
    ],
  },
] as IRoutes[];
