import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  {
    path: '/:urlOrgId/customers/:businessId/business-contact/:contactId',
    title: 'Contact details',
    component: lazy(() => import('components/views/BusinessContactDetail')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['businesses_read', 'businesses_report'],
    routes: [
      {
        title: 'Businesses',
        path: '/:urlOrgId/businesses',
        params: [],
      },
      {
        title: 'Business Details',
        path: '/:urlOrgId/businesses/:businessId',
        params: ['businessId'],
      },
      {
        title: 'Contacts',
        path: '/:urlOrgId/businesses/:businessId#contacts',
        params: ['businessId'],
      },
    ],
  },
  {
    path: '/:urlOrgId/customers/:businessId/business-contact/:id/edit',
    title: 'Edit Contact',
    component: lazy(() => import('components/modules/form/BusinessContactEditForm')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: [],
    routes: [
      {
        title: 'Businesses',
        path: '/:urlOrgId/businesses',
        params: [],
      },
      {
        title: 'Business Details',
        path: '/:urlOrgId/businesses/:businessId',
        params: ['businessId'],
      },
      {
        title: 'Contacts',
        path: '/:urlOrgId/businesses/:businessId#contacts',
        params: ['businessId'],
      },
      {
        title: 'Contact Details',
        path: '/:urlOrgId/businesses/:businessId/business-contact/:id',
        params: ['businessId', 'id'],
      },
    ],
  },
  {
    path: '/:urlOrgId/customers/:businessId/business-contact/:contactId/kyc-result/:resultId',
    title: 'KYC Result',
    component: lazy(() => import('components/views/KYCResult')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['sp_verification_read'],
    routes: [
      {
        title: 'Businesses',
        path: '/:urlOrgId/businesses',
        params: [],
      },
      {
        title: 'Business Details',
        path: '/:urlOrgId/businesses/:businessId',
        params: ['businessId'],
      },
      {
        title: 'Contacts',
        path: '/:urlOrgId/businesses/:businessId#contacts',
        params: ['businessId'],
      },
      {
        title: 'Contact Details',
        path: '/:urlOrgId/businesses/:businessId/business-contact/:id',
        params: ['businessId', 'id'],
      },
    ],
  }
] as IRoutes[];
