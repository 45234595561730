import { createAsyncThunk } from '@reduxjs/toolkit';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import riskAlertsService from '../../../services/riskAlerts';
// TYPES
import { IApproveValues, IDepositData, IRejectData, IUpdateValues, IWithdrawData } from './index.D';

export default {
  getAlert: createAsyncThunk(
    'alert/get',
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(() => riskAlertsService.findById(id), thunkAPI, false)
  ),
  rejectAlert: createAsyncThunk(
    'alert/reject',
    async ({ id, text }: IApproveValues, thunkAPI) =>
      await storeAddErrorNotification(() => riskAlertsService.reject(id, text), thunkAPI, false)
  ),
  deleteAlert: createAsyncThunk(
    'alert/delete',
    async ({ id, text }: IApproveValues, thunkAPI) =>
      await storeAddErrorNotification(() => riskAlertsService.delete(id, text), thunkAPI, false)
  ),
  acceptAlert: createAsyncThunk(
    'alert/accept',
    async ({ id, text, txId }: IApproveValues, thunkAPI) =>
      await storeAddErrorNotification(() => riskAlertsService.accept(id, text, txId), thunkAPI, false)
  ),
  findAlertByCustomer: createAsyncThunk(
    'alert/findAMLByCustomer',
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(() => riskAlertsService.findAlertByCustomer(id), thunkAPI, false)
  ),
  findAlertByBusiness: createAsyncThunk(
    'alert/findAMLByBusiness',
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(() => riskAlertsService.findAlertByBusiness(id), thunkAPI, false)
  ),
  findAlertByTransfer: createAsyncThunk(
    'alert/findAlertByTransfer',
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(() => riskAlertsService.findAlertByTransfer(id), thunkAPI, false)
  ),
  updateAlert: createAsyncThunk(
    'alert/update',
    async ({ id, status }: IUpdateValues, thunkAPI) =>
      await storeAddErrorNotification(() => riskAlertsService.update(id, status), thunkAPI, false)
  ),
  submitDeposit: createAsyncThunk(
    'alert/submitDeposit',
    async (data: IDepositData, thunkAPI) =>
      await storeAddErrorNotification(() => riskAlertsService.submitDeposit(data), thunkAPI, false)
  ),
  rejectDeposit: createAsyncThunk(
    'alert/rejectDeposit',
    async (data: IRejectData, thunkAPI) =>
      await storeAddErrorNotification(() => riskAlertsService.rejectTransaction(data), thunkAPI, false)
  ),
  submitWithdraw: createAsyncThunk(
    'alert/submitWithdraw',
    async (data: IWithdrawData, thunkAPI) =>
      await storeAddErrorNotification(() => riskAlertsService.submitWithdraw(data), thunkAPI, false)
  ),
  rejectWithdraw: createAsyncThunk(
    'alert/rejectWithdraw',
    async (data: IRejectData, thunkAPI) =>
      await storeAddErrorNotification(() => riskAlertsService.rejectTransaction(data), thunkAPI, false)
  ),
};
