import { lazy } from '@loadable/component';

export default [
  {
    path: '/risk-rules',
    title: 'Risk Rules',
    component: lazy(() => import('components/views/WithdrawalRules')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['risk_rules_read'],
    routes: [],
  },
];
