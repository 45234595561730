import { lazy } from '@loadable/component';
import { IRoutes } from '../routes.D';

export default [
  // {
  // 	path:        '/organizations-fees',
  // 	title:       'Organizations Fees',
  // 	component:   lazy(() => import('components/views/OrganizationsFees')),
  // 	layout:      lazy(() => import('components/layouts/MainLayout')),
  // 	exact:       true,
  // 	private:     true,
  // 	permissions: ['margins_fees_read'],
  // 	routes:      []
  // },
  {
    path: '/markup-management',
    title: 'Jigzo Markup Management',
    component: lazy(() => import('components/views/TransactionsMarginsFees')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['margins_fees_read'],
    routes: [],
  },
  {
    path: '/:urlOrgId/approved-partner-markup-management',
    title: 'Approved Partner markup management',
    component: lazy(() => import('components/views/TransactionsMarginsFees')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['margins_fees_read'],
    routes: [],
  },
  {
    path: '/announcement',
    title: 'Announcements',
    component: lazy(() => import('components/views/Announcement')),
    layout: lazy(() => import('components/layouts/MainLayout')),
    exact: true,
    private: true,
    permissions: ['announcements_read'],
    routes: [],
  },
] as IRoutes[];
